import React, { useEffect, useState } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../images/logo.png";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import Product from "../../images/Product/Tables-Ottomans/table/img1.jpg";
import {
  useFirebaseApp,
  useFirestore,
  useFirestoreCollectionData,
  useUser,
} from "reactfire";
import { navigate } from "gatsby-link";
import "firebase/auth";
import "firebase/firestore";
import TextTruncate from "react-text-truncate";
import Loader from "../../components/Loader";
import { useForm } from "react-hook-form";

const Admin = (props) => {
  const firebaseApp = useFirebaseApp();
  const firestore = useFirestore(firebaseApp);

  const [filteredStatus, setFilteredStatus] = useState({
    search: "",
    filterStatus: "All Categories",
  });

  // const { status, data: signInCheckResult } = useSigninCheck();
  // let signedIn = false;
  // let isAdmin = false;

  const [signedIn, setSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [disableProduct, setDisableProduct] = useState(false);

  let productsCollectionRef = filteredStatus;
  // console.log("Filtered Status: " + JSON.stringify(filteredStatus));

  if (filteredStatus.filterStatus != "All Categories") {
    // console.log("Category1: " + filteredStatus.filterStatus);
    productsCollectionRef = firestore
      .collection("Products")
      .where("category", "==", filteredStatus.filterStatus);
  } else {
    // console.log("Category2: " + filteredStatus.filterStatus);

    productsCollectionRef = firestore.collection("Products");
  }

  // switch (filteredStatus.filterStatus) {
  //   case "Tables & Ottomans":
  //     productsCollectionRef = firestore
  //       .collection("Products")
  //       .where("Category", "==", "Tables & Ottomans");
  //     break;
  //   case "TV & Media Units":
  //     console.log("-TV & Media Units");
  //     break;
  //   case "Chairs & Arm Chairs":
  //     productsCollectionRef = firestore
  //       .collection("Products")
  //       .where("Category", "==", "Chairs & Arm Chairs");
  //     break;
  //   case "Sofas":
  //     console.log("Sofas");
  //     break;
  //   case "Shelves & Storages":
  //     console.log("-Shelves & Storages");
  //     break;
  //   case "Benches":
  //     console.log("-Benches");
  //     break;
  //   case "Lightings":
  //     console.log("-Lightings");
  //     break;
  //   case "Home Decor":
  //     console.log("-Home Decor");
  //     break;
  //   case "Accessories":
  //     console.log("-Accessories");
  //     break;
  //   default:
  //     console.log("-All Categories");
  // }

  // if (filteredStatus.filterStatus == "A-Z") {
  //   productsCollectionRef = firestore
  //     .collection("Products")
  //     .orderBy("name", "asc");

  //   console.log("Ref1 ");
  // } else if (filteredStatus.filterStatus == "Z-A") {
  //   productsCollectionRef = firestore
  //     .collection("Products")
  //     .orderBy("name", "desc");
  //   console.log("Ref2 ");
  // } else {
  //   productsCollectionRef = firestore
  //     .collection("Products")
  //     .orderBy("timestamp", "desc");
  //   console.log("Ref3 ");
  // }
  const { data } = useFirestoreCollectionData(productsCollectionRef);

  // console.log("Firestore Data: " + JSON.stringify(data));
  const { data: user } = useUser();

  const signOut = () => {
    window.localStorage.setItem("isSignedIn", false);
    navigate("/Login", { state: { from: "signOut" } });
  };

  let admin = false;
  let signedIn1 = false;

  // console.log(
  //   "Window Output Admin1: " + window.localStorage.getItem("isSignedIn")
  // );

  useEffect(() => {
    // if (status === "loading") {
    //   console.log("Loading...");
    //   return <Loader />;
    // } else {

    // console.log(
    //   "Window Output Admin2: " + window.localStorage.getItem("isSignedIn")
    // );

    if (
      window.localStorage.getItem("isSignedIn") != undefined &&
      window.localStorage.getItem("isSignedIn") == "true"
    ) {
      // console.log("User Object: " + JSON.stringify(user));
      // setSignedIn(true);
      // signedIn = true;

      signedIn1 = true;

      getFirestoreData(user);
    } else {
      navigate("/Login");
    }
    // }

    // console.log("Useeffect1 ke andar: " + admin);
  }, [user, window.localStorage.getItem("isSignedIn")]);

  const getFirestoreData = async (user) => {
    if (user != undefined) {
      await firestore
        .collection("Users")
        .where("email", "==", user.email)
        .get()
        .then((snapshot) => {
          if (snapshot.docs[0].data().admin != undefined) {
            // setIsAdmin(true)
            // isAdmin = true
            admin = true;
            // console.log("snapshot ki condtion: true");
          } else {
            // setIsAdmin(false);
            // isAdmin = false;
            admin = false;
            navigate("/Login");
            // console.log("snapshot ki condtion: false");
          }
        });

      setSignedIn(signedIn1);
      setIsAdmin(admin);
    } else {
      return <Loader />;
    }
  };

  const filterUserStatusChange = (e) => {
    e.preventDefault();

    setFilteredStatus({
      ...filteredStatus,
      [e.target.name]: e.target.value,
      error: "",
    });

    // console.log("Changed User: " + e.target.value);
  };

  // const checkIfProductDisabled = (productEnabled) => {
  //   setDisableProduct(productEnabled)
  // }

  const disableProduct1 = (idField) => {
    setDisableProduct(true);
    // console.log("ID Field: " + idField);
    firestore
      .collection("Products")
      .doc(idField)
      .update({ enabled: disableProduct });
  };

  const enableProduct = (idField) => {
    setDisableProduct(false);
    // console.log("ID Field: " + idField);
    firestore
      .collection("Products")
      .doc(idField)
      .update({ enabled: disableProduct });
  };

  // console.log("SignedIn: " + signedIn + " isAdmin: " + isAdmin);
  if (signedIn && isAdmin) {
    const getProducts = (productData) => {
      return productData.map((product) => {
        return (
          <tbody>
            <tr>
              <th scope="row">
                <img src={product.imagesUrl[0]} alt="img" />
              </th>
              <td>{product.category}</td>
              <td>{product.name}</td>
              <td>
                <TextTruncate
                  line={1}
                  element="td"
                  truncateText="..."
                  text={product.dimensions}
                />
              </td>
              {/* <td>{product.finishes}</td>
              <td>
                <TextTruncate
                  line={1}
                  element="td"
                  truncateText="..."
                  text={product.description}
                />
              </td> */}
              <td>
                {/* {product.enabled && checkIfProductDisabled(product.enabled)} */}
                {!product.enabled ? (
                  <Button
                    style={{ backgroundColor: "green" }}
                    onClick={() => disableProduct1(product.NO_ID_FIELD)}
                  >
                    Enable
                  </Button>
                ) : (
                    <Button
                      style={{ backgroundColor: "red" }}
                      onClick={() => enableProduct(product.NO_ID_FIELD)}
                    >
                      Disable
                  </Button>
                  )}
              </td>

              <td>
                <Button
                  onClick={() =>
                    navigate("EditProduct/", {
                      state: { isFromEdit: "true", productData: product },
                    })
                  }
                >
                  Edit
                </Button>
              </td>
            </tr>
          </tbody>
        );
      });
    };

    return (
      <div className="admin">
        <div className="admin_header">
          {/* <Container> */}
          <Row>
            <Col md="4">
              <div className="admin_logo">
                <img src={Logo} alt="img" />
              </div>
            </Col>
            <Col md="4">
              <div>
                <Form onChange={filterUserStatusChange}>
                  <FormGroup row className="Search">
                    <Col sm={3} className="main_admin">
                      {/* <Input placeholder="Search" /> */}
                    </Col>

                    <Col sm={9} className="main_admin">
                      {/* <p>Sort By: </p> */}
                      <Input
                        type="select"
                        name="filterStatus"
                        id="exampleSelect"
                      >
                        <option value="All Categories">All Categories</option>
                        <option value="Tables & Ottomans">
                          Tables & Ottomans
                        </option>
                        <option value="TV & Media Units">
                          TV & Media Units
                        </option>
                        <option value="Chairs & Arm Chairs">
                          Chairs & Arm Chairs
                        </option>
                        <option value="Sofas">Sofas</option>
                        <option value="Shelves & Storages">
                          Shelves & Storages
                        </option>
                        <option value="Benches">Benches</option>
                        <option value="Lightings">Lightings</option>
                        <option value="Home Decor">Home Decor</option>
                        <option value="Accessories">Accessories</option>
                      </Input>
                    </Col>
                  </FormGroup>
                </Form>
              </div>
            </Col>
            <Col md="4">
              <div className="admin_text">
                <p onClick={() => signOut()}>Log Out</p>
              </div>
            </Col>
          </Row>
          {/* <div className="admin_row">
              <div className="admin_logo">
                <img src={Logo} alt="img" />
              </div>
              <div>
                <input placeholder="search"/>
              </div>
              <div className="admin_text">
                <p onClick={() => signOut()}>Log Out</p>
              </div>
            </div> */}
          {/* </Container> */}
        </div>
        <Row>
          <Col md="2">
            <div className="left_section">
              <div>
                <ul>
                  <li>
                    <a href="/admin/">Listed Products</a>
                  </li>
                  <li>
                    <a href="/admin/AddedProduct/">Add a Product</a>
                  </li>
                  <li>
                    <a href="/admin/NewRegisters/">Users</a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md="10">
            <section className="right_section">
              <div style={{ color: '#000', textTransform: 'uppercase' }}>Listed Products</div>
              <Row>
                <Col md="12">
                  <Table>
                    <thead>
                      <tr>
                        <th>IMG</th>
                        <th>Product Category</th>
                        <th>Product Title</th>
                        <th>Product SKU/Diminesions</th>
                        {/* <th>Finishes</th>
                      <th>Description</th> */}
                      </tr>
                    </thead>

                    {/* {console.log("Data: "+JSON.stringify(data[0].NO_ID_FIELD))} */}
                    {data && getProducts(data)}
                  </Table>
                  <Button href="/admin/AddedProduct/">Add new product</Button>
                </Col>
                {/* <Col md="4">
                            <div>
                                abc3
                            </div>

                        </Col> */}
              </Row>
            </section>
          </Col>
        </Row>

      </div>
    );
  } else {
    return null;
  }
};

export default Admin;
